import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FrontPage from "./frontPage"
import Modal from "../components/modal"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Magick" />
      <Modal/>
      <FrontPage/>
    </Layout>
  )}

export default IndexPage
