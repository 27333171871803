import React from "react"
import { Link } from "gatsby"
import "./styles.sass"
import { Store } from "../../store/Store"

const MobileMenu = () => {
  const {dispatch} = Store()
  const closeMobileMenu = () => dispatch({type: "closeMobileMenu"})

  return (
    <div className="mobile-menu">
      <div className="mobile-menu--link" onClick={closeMobileMenu}>
        <a name="#spoon" href="#spoon">Spoon</a>
      </div>
      <div className="mobile-menu--link" onClick={closeMobileMenu}>
        <a name="#works" href="#work">Work</a>
      </div>
      <div className="mobile-menu--link" onClick={closeMobileMenu}>
        <a name="#works" href="#secrets">Secrets</a>
      </div>
      <div className="mobile-menu--link" onClick={closeMobileMenu}>
        <a name="#contact" href="#contact">Contact</a>
      </div>
      <div className="mobile-menu--link">
        <Link
          exit={{
            length: 1
          }}
          entry={{
            delay: 0
          }}
          state={{ animateFrom: 'right' }}
          to="/imprint" >
          Imprint
        </Link>
      </div>
      <div className="mobile-menu--link">
        <Link
          exit={{
            length: 1
          }}
          entry={{
            delay: 0
          }}
          state={{ animateFrom: 'left' }}
          to="/privacy" >
          Privacy
        </Link>
      </div>
    </div>
  )
}

export default MobileMenu