import React from "react"
import { Store } from "../../store/Store";
import Loadable from "@loadable/component"

import MobileMenu from "../mobileMenu"
const Case = Loadable(() => import("../portfolio/case"))



const Modal = () => {
  const {state} = Store()

  return (
      <>
        {state.caseIsVisible &&
        <Case/>
        }
        {state.mobileMenuIsOpen &&
        <MobileMenu/>
        }
      </>
  )
}

export default Modal
